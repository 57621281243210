import React, { useContext } from 'react';
import { useAuth } from 'react-oidc-context';

import MenuWrapper from './menu-wrapper';
import MenuItem from '../components/menu-item';
import { Permission } from '../types/permission';
import { LayoutContext } from '../contexts/layout-provider';

/**
 * The menu used for the private (main authenticated) area layouts.
 */
const PrivateMenu: React.FC<{ classname?: string }> = ({ classname = '' }) => {
  const auth = useAuth();
  const userRoles = auth.user?.profile.role as string[];
  const layout = useContext(LayoutContext);

  layout.setDrawerMobileMenu(
    <PrivateMenu classname={'app-side-nav--mobile-drawer'} />
  );

  return (
    <MenuWrapper classname={classname}>
      {/* prettier-ignore */}
      <div className={'aside-menu'}>
        <MenuItem to={'/dashboard'} icon={'home'} label={'Dashboard'} />
        {userRoles && (
          <>
            <MenuItem to={'#'} icon={'domain'} label={'My organisation'} requiredRole={Permission.Organisation}>
              <MenuItem to={'/myorganisation'} icon={'info'} label={'Info'} requiredRole={Permission['Organisation.Info']} />
              <MenuItem to={'/users'} icon={'group'} label={'Members'} requiredRole={Permission['Organisation.Members']} />
              {/* hide this menu regarding task 6615 */}
              {/* <MenuItem to={'/displaylanguage'} icon={'translate'} label={'Display language'} requiredRole={Permission['Organisation.DisplayLanguage']} /> */}
            </MenuItem>
            <MenuItem to={'#'} icon={'public'} label={'My network'} requiredRole={Permission.Network}>
              <MenuItem to={'/networkorganisations'} icon={'domain'} label={'Organisations'} requiredRole={Permission['Network.Organisations']} />
              <MenuItem to={'/networkmembers'} icon={'group'} label={'Members'} requiredRole={Permission['Network.Members']} />
            </MenuItem>
            <hr />
            <MenuItem to={'/myevents'} icon={'calendar_month'} label={'My events'} requiredRole={Permission.Events} />
            <MenuItem to={'#'} icon={'library_books'} label={'My library'} requiredRole={Permission.MyLibrary}>
              <MenuItem to={'/mylibrary/games'} icon={'library_books'} label={'Games'} requiredRole={Permission['MyLibrary.Games']} />
              <MenuItem to={'/mylibrary/assessment'} icon={'task'} label={'Assessment'} requiredRole={Permission['MyLibrary.Assessments']} />
              <MenuItem to={'/mylibrary/feedback'} icon={'insert_chart'} label={'Feedback'} requiredRole={Permission['MyLibrary.Feedback']} />
            </MenuItem>
            <MenuItem to={'#'} icon={'collections_bookmark'} label={'Global library'} requiredRole={Permission.GlobalLibrary}>
              <MenuItem to={'/global-games'} icon={'collections_bookmark'} label={'Games'} requiredRole={Permission['GlobalLibrary.Games']} />
              <MenuItem to={'/global-assessment'} icon={'task'} label={'Assessment'} requiredRole={Permission['GlobalLibrary.Assessments']} />
              <MenuItem to={'/global-feedback'} icon={'insert_chart'} label={'Feedback'} requiredRole={Permission['GlobalLibrary.Feedback']} />
            </MenuItem>
           
            <MenuItem to={'/reports'} icon={'monitoring'} label={'Reports'} requiredRole={Permission.Reports} />
            </>            
        )}
        
      </div>
      <hr />
      {/* prettier-ignore */}
      {userRoles && (
        <>
          <div className={'aside-menu'}>
            <MenuItem
              to={'/administration'}
              icon={'admin_panel_settings'}
              label={'Administration'}
              className={'text-danger'}
              requiredRole={Permission.Admin}
            />
            <MenuItem
              to={'/diagnostics'}
              icon={'developer_mode'}
              label={'Diagnostics'}
              className={'text-secondary'}
            />
          </div>
        </>
      )}
    </MenuWrapper>
  );
};

export default PrivateMenu;
