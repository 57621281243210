import React from 'react';
import { ExpansionPanelContent } from '@progress/kendo-react-layout';

type ExpansionPanelContentProps = React.ComponentProps<
  typeof ExpansionPanelContent
>;

export const ExpansionContentDisabledKeys: React.FC<
  ExpansionPanelContentProps
> = ({ children, ...props }) => {
  return (
    <ExpansionPanelContent
      {...props}
      onKeyDown={(e) => {
        if (e.key === ' ' || e.key === 'Enter') {
          e.stopPropagation();
        }
      }}>
      {children}
    </ExpansionPanelContent>
  );
};
