import { useContext } from 'react';

import MenuItem from '../components/menu-item';
import { LayoutContext } from '../contexts/layout-provider';
import MenuWrapper from './menu-wrapper';

/**
 * The menu used for the diagnostics area layouts.
 */
const DiagnosticsMenu: React.FC<{ classname?: string }> = ({
  classname = ''
}) => {
  const layout = useContext(LayoutContext);

  layout.setDrawerMobileMenu(
    <DiagnosticsMenu classname={'app-side-nav--mobile-drawer'} />
  );

  return (
    <MenuWrapper classname={classname}>
      {/* prettier-ignore */}
      <div className={'aside-menu'}>
        <MenuItem to={'/'} icon={'chevron_left'} label={'Back to dashboard'} className={'text-success'} />
      </div>
      <hr />
      {/* prettier-ignore */}
      <div className={'aside-menu'}>
        <MenuItem to={''} icon={'developer_mode'} label={'Overview'} />
        <MenuItem to={'user'} icon={'data_object'} label={'User'} />
        <MenuItem to={'auth'} icon={'data_object'} label={'Auth settings'} />
      </div>
    </MenuWrapper>
  );
};

export default DiagnosticsMenu;
