import { User } from 'oidc-client-ts';
import { AuthProvider } from 'react-oidc-context';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { Admin, Authentication, Designer, Private, Public } from './layouts';
import Diagnostics from './layouts/diagnostics';
import Protected from './layouts/protected';
import {
  default as Administration,
  Environment,
  Environments
} from './pages/administration';
import AdminInviteUser from './pages/administration/admin-invite-user';
import AllUserPage from './pages/administration/alluser';
import AllUsers from './pages/administration/allusers';
import GlobalLibrariesPending from './pages/administration/global-libraries-pending';
import { LibrarApprovalPage } from './pages/administration/library-approval';
import Assessment from './pages/assessment/assessment';
import Dashboard from './pages/dashboard';
import {
  AuthDiagnostics,
  default as DiagnosticsPage,
  UserDiagnostics
} from './pages/diagnostics';
import { PageNotFound } from './pages/errors';
import Feedback from './pages/feedback/feedback';
import FeedbackDetail from './pages/feedback/feedback-detail';
import GameDesigner from './pages/game-designer';
import GlobalLibrary from './pages/global-libraries';
import InviteUser from './pages/invite-user';
import { LibraryPage } from './pages/library';
import MyEvents from './pages/my-events';
import MyEventDetails from './pages/my-events-details';
import MyEventView from './pages/my-events-view';
import Mylibrary from './pages/mylibrary';
import MyLibraryNew from './pages/mylibrary-new';
import Organisation from './pages/organisation';
import OrganisationView from './pages/organisation-view';
import Organisations from './pages/organisations';
import { PrivacyPolicy, TermsOfUse } from './pages/public';
import Report from './pages/report';
import Reports from './pages/reports';
import UserPage from './pages/my-organisation/user';
import Users from './pages/my-organisation/users';
import { Permission } from './types/permission';
import AssessmentDetail from './pages/assessment/assessment-detail';
import AssessmentApproval from './pages/administration/approval/assessment-approval';
import AssessmentDetailView from './pages/assessment/assessment-detail-view';
import FeedbackApproval from './pages/administration/approval/feedback-approval';
import FeedbackDetailView from './pages/feedback/feedback-detail-view';
import GlobalAssessment from './pages/global-assessment/assessment';
import { GlobalAssessmentDetail } from './pages/global-assessment/assessment-detail';
import GlobalFeedback from './pages/global-feedback/feedback';
import GlobalFeedbackDetail from './pages/global-feedback/feedback-detail';
import MyOrganisation from './pages/my-organisation/my-organisation';
import SupportTickets from './pages/administration/support-tickets';
import SupportTicketDetail from './pages/administration/support-ticket-detail';
import FeedbackReadonly from './pages/feedback/feedback-readonly';
import AssessmentReadonly from './pages/assessment/assessment-readonly';
import NetworkOrganisations from './pages/my-network/network-organisations';
import NetworkMembers from './pages/my-network/network-members';
import GamesManagement from './pages/administration/global-libraries/games-management';
import AssessmentsManagement from './pages/administration/global-libraries/assessments-management';
import FeedbacksManagement from './pages/administration/global-libraries/feedbacks-management';
import LanguagesManagement from './pages/administration/global-libraries/language/languages-management';
import DisplayLanguageEdit from './pages/administration/global-libraries/language/language-edit';
import EventsManagement from './pages/administration/events-management';
import { InstanceProvider } from './contexts/';
import instanceConfig from './instance';
import { AssessmentMonacoPage } from './pages/assessment/assessment-monaco';
import ActiveEvents from './pages/administration/active-events';
import { LayoutProvider } from './contexts/layout-provider';

declare global {
  interface Window {
    _env_: any;
  }
}

// prettier-ignore
const oidcConfig = {
  onSignIn: () => {
    // Redirect?
  },
  onSigninCallback: (_user: User | void): void => {
    window.history.replaceState( {}, document.title, window.location.pathname);
  },
  authority: window?._env_?.REACT_APP_AUTHORITY || process.env.REACT_APP_AUTHORITY,
  client_id: window?._env_?.REACT_APP_CLIENT_ID || process.env.REACT_APP_CLIENT_ID,
  redirect_uri: window?._env_?.REACT_APP_REDIRECT_URI || process.env.REACT_APP_REDIRECT_URI,
  scope: 'offline_access openid profile', // offline_access scope allow your client to retrieve the refresh_token
  silent_redirect_uri: window?._env_?.REACT_APP_SILENT_REDIRECT_URI || process.env.REACT_APP_SILENT_REDIRECT_URI,
  loadUserInfo: true,
  post_logout_redirect_uri: window?._env_?.REACT_APP_REDIRECT_URI || process.env.REACT_APP_REDIRECT_URI
};

// prettier-ignore
function App() {
  return (
    <InstanceProvider {...instanceConfig}>
    <AuthProvider {...oidcConfig}>
        <BrowserRouter>
          <LayoutProvider>
            <Routes>
              <Route element={<Public />}>
                <Route path={'/privacy-policy'} element={<PrivacyPolicy />} />
                <Route path={'/terms-of-use'} element={<TermsOfUse />} />
              </Route>          
              <Route path={'/'} element={<Private />}>
                <Route index element={<Dashboard />} />
                <Route path={'dashboard/'} element={<Dashboard />} />
                <Route path={'users/'} element={<Protected allowedRole={Permission['Organisation.Members']} />}>
                  <Route index element={<Users />} />
                  <Route path={':id'} element={<UserPage />} />
                  <Route path={'invite-user/'} element={<InviteUser />} />
                  <Route path={'new'} element={<UserPage />} />
                </Route>
                <Route path={'mylibrary/games/'} element={<Protected allowedRole={Permission['MyLibrary.Games']} />}>
                  <Route index element={<Mylibrary />} />
                  <Route path={'new'} element={<MyLibraryNew />} />
                </Route>
                <Route path={'global-games/'} element={<Protected allowedRole={Permission['GlobalLibrary.Games']} />}>
                  <Route index element={<GlobalLibrary />} />
                  <Route path={':id'} element={<LibraryPage />} />
                </Route>
                <Route path={'mylibrary/assessment/'} element={<Protected allowedRole={Permission['MyLibrary.Assessments']} />}>
                  <Route index element={<Assessment />} />
                  <Route path={'new'} element={<AssessmentDetail />} />
                </Route>
                <Route path={'assessment-details/:id'} element={<Protected allowedRole={Permission['MyLibrary.Assessments']} />}>
                  <Route index element={<AssessmentDetail />} />
                  <Route path={'monaco'} element={<AssessmentMonacoPage />} />
                </Route>
                <Route path={'assessment-detail-view/:id'} element={<Protected allowedRole={Permission['MyLibrary.Assessments']} />}>
                  <Route index element={<AssessmentReadonly />} />
                </Route>
                <Route path={'global-assessment/'} element={<Protected allowedRole={Permission['GlobalLibrary.Assessments']} />}>
                  <Route index element={<GlobalAssessment />} />
                  <Route path={'new'} element={<GlobalAssessmentDetail />} />
                </Route>
                <Route path={'global-assessment-details/:id'} element={<Protected allowedRole={Permission['GlobalLibrary.Assessments']} />}>
                  <Route index element={<GlobalAssessmentDetail />} />
                </Route>
                <Route path={'mylibrary/feedback/'} element={<Protected allowedRole={Permission['MyLibrary.Feedback']} />}>
                  <Route index element={<Feedback />} />
                  <Route path={'new'} element={<FeedbackDetail />} />
                </Route>
                <Route path={'feedback-details/:id'} element={<Protected allowedRole={Permission['MyLibrary.Feedback']} />}>
                  <Route index element={<FeedbackDetail />} />
                </Route>
                <Route path={'feedback-detail-readonly/:id'} element={<Protected allowedRole={Permission['MyLibrary.Feedback']} />}>
                  <Route index element={<FeedbackReadonly />} />
                </Route>
                <Route path={'global-feedback/'} element={<Protected allowedRole={Permission['GlobalLibrary.Feedback']} />}>
                  <Route index element={<GlobalFeedback />} />
                  <Route path={'new'} element={<GlobalFeedbackDetail />} />
                </Route>
                <Route path={'global-feedback-details/:id'} element={<Protected allowedRole={Permission.Events} />}>
                  <Route index element={<GlobalFeedbackDetail />} />
                </Route>
                <Route path={'myevents/'} element={<Protected allowedRole={Permission.Events} />}>
                  <Route index element={<MyEvents />} />
                </Route>
                <Route path={'myorganisation/'} element={<Protected allowedRole={Permission['Organisation.Info']} />}>
                  <Route index element={<MyOrganisation />} />
                </Route>
                <Route path={'networkorganisations/'} element={<Protected allowedRole={Permission['Network.Organisations']} />}>
                  <Route index element={<NetworkOrganisations />} />
                </Route>
                <Route path={'networkmembers/'} element={<Protected allowedRole={Permission['Network.Members']} />}>
                  <Route index element={<NetworkMembers />} />
                </Route>
                {/* hide this route regarding task 6615 */}
                {/* <Route path={'displaylanguage/'} element={<Protected allowedRole={Permission['Organisation.DisplayLanguage']} />}>
                  <Route index element={<MyOrganisationDisplayLanguage />} />
                </Route> */}
                <Route path={'myeventdetails/:id'} element={<Protected allowedRole={Permission.Events} />}>
                  <Route index element={<MyEventDetails />} />
                </Route>
                <Route path={'myeventview/:id'} element={<Protected allowedRole={Permission.Events} />}>
                  <Route index element={<MyEventView />} />
                </Route>
                <Route path={'reports/'} element={<Protected allowedRole={Permission.Reports} />}>
                  <Route index element={<Reports />} />
                  <Route path={':id'} element={<Report />} />
                </Route>         
              </Route>
              <Route path={'/game-library/:gameId'} element={<Designer />}>
              </Route>
              <Route path={'/designer'}>
                <Route path={':gameId/*'} element={<GameDesigner />} />
              </Route>
              <Route path={'/administration'} element={<Admin />}>
              <Route path={'users/'} element={<AllUsers />} />
              <Route path={'users/invite-user/'} element={<AdminInviteUser />} />
              <Route path={'users/:id'} element={<AllUserPage />} />
              <Route path={'organisations/'} element={<Organisations />} />
                <Route path={'organisation/:id'} element={<Organisation />} />
                <Route path={'organisations/new'} element={<Organisation />} />
                <Route path={'organisation-details/:id'} element={<OrganisationView />} />

                <Route path={'globallibraries/pending'} element={<GlobalLibrariesPending />} />
                <Route path={'libraryapproval/:id'} element={<LibrarApprovalPage />}/>

                <Route index element={<Administration />} />
                <Route path={'users/'} element={<Protected allowedRole={Permission['Admin.Users']} />}>
                  <Route index element={<AllUsers />} />
                  <Route path={':id'} element={<AllUserPage />} />
                </Route>
                <Route path={'organisations/'} element={<Protected allowedRole={Permission['Admin.Organisations']} />}>
                  <Route index element={<Organisations />} />
                  <Route path={':id'} element={<Organisation />} />
                  <Route path={'new'} element={<Organisation />} />
                </Route>
                <Route path={'organisation/:id'} element={<Protected allowedRole={Permission['Admin.Organisations']} />}>
                  <Route index element={<Organisation />} />
                </Route>
                <Route path={'organisation-details/:id'} element={<Protected allowedRole={Permission['Admin.Organisations']} />}>
                  <Route index element={<OrganisationView />} />
                </Route>
                <Route path={'events/manage'} element={<Protected allowedRole={Permission['Admin.Events']} />}>
                  <Route index element={<EventsManagement />} />
                </Route> 
                <Route path={'globallibraries/pending/'} element={<Protected allowedRole={Permission['Admin.PendingApproval']} />}>
                  <Route index element={<GlobalLibrariesPending />} />
                </Route>
                <Route path={'games/manage'} element={<Protected allowedRole={Permission['Admin.PendingApproval']} />}>
                  <Route index element={<GamesManagement />} />
                </Route>
                <Route path={'languages/manage'} element={<Protected allowedRole={Permission['Admin.Languages']} />}>
                  <Route index element={<LanguagesManagement />} />
                  <Route path={':id'} element={<DisplayLanguageEdit />} />
                </Route>
                <Route path={'assessments/manage'} element={<Protected allowedRole={Permission['Admin.PendingApproval']} />}>
                  <Route index element={<AssessmentsManagement />} />
                </Route>
                <Route path={'feedbacks/manage'} element={<Protected allowedRole={Permission['Admin.PendingApproval']} />}>
                  <Route index element={<FeedbacksManagement />} />
                </Route>
                <Route path={'feedbacks/pending/'} element={<Protected allowedRole={Permission['Admin.PendingApproval']} />}>
                  <Route index element={<FeedbackApproval />} />
                </Route>  
                <Route path={'feedback-detail-view/:id'} element={<Protected allowedRole={Permission['Admin.PendingApproval']} />}>
                  <Route index element={<FeedbackDetailView />} />
                </Route>
                <Route path={'assessments/pending/'} element={<Protected allowedRole={Permission['Admin.PendingApproval']} />}>
                  <Route index element={<AssessmentApproval />} />
                </Route>   
                <Route path={'support_tickets'} element={<Protected allowedRole={Permission['Admin.SupportTickets']} />}>
                  <Route index element={<SupportTickets/>} />
                </Route>   
                <Route path={'support_tickets/:id'} element={<Protected allowedRole={Permission['Admin.SupportTickets']} />}>
                  <Route index element={<SupportTicketDetail/>} />
                </Route>  
                <Route path={'active-events'} element={<Protected allowedRole={Permission['Admin.ActiveEvents']} />}>
                  <Route index element={<ActiveEvents />} />
                </Route>  
                <Route path={'assessment-detail-view/:id'} element={<Protected allowedRole={Permission['Admin.PendingApproval']} />}>
                  <Route index element={<AssessmentDetailView />} />
                </Route>
                <Route path={'libraryapproval/:id'} element={<Protected allowedRole={Permission['Admin.PendingApproval']} />}>
                  <Route index element={<LibrarApprovalPage />}/>
                </Route>
                <Route path={'environments/'} element={<Protected allowedRole={Permission['Admin.Environments']} />}>
                  <Route index element={<Environments />} />
                </Route>
                <Route path={'environment/:id'} element={<Protected allowedRole={Permission['Admin.Environments']} />}>
                  <Route index element={<Environment />} />
                </Route>
              </Route>
              <Route path={'/diagnostics'} element={<Diagnostics />}>
                <Route index element={<DiagnosticsPage />} />
                <Route path={'user'} element={<UserDiagnostics />} />
                <Route path={'auth'} element={<AuthDiagnostics />} />
              </Route>
              <Route element={<Authentication />}>
                <Route path={'*'} element={<PageNotFound />} />
              </Route>
            </Routes>
          </LayoutProvider>
        </BrowserRouter>
    </AuthProvider>
    </InstanceProvider>
  );
}

export default App;
