export const DefaultTextcolor = 'black';
export const DefaultStrokeColor = 'white';
export const DefaultFontFamily = 'bold 12px Open Sans';

export const MAP_OBJECT_TASK = 'TASK';
export const MAP_OBJECT_ZONE = 'ZONE';
export const MAP_OBJECT_AREA = 'AREA';
export const MAP_OBJECT_IMAGE = 'IMAGE';
export const MAP_OBJECT_OVERLAY_IMAGE = 'OVERLAY_IMAGE';
export const MAP_OBJECT_ALL = 'ALL';
