import { useContext, useEffect, useRef, useState } from 'react';
import ImageLayer from 'ol/layer/Image';
import Projection from 'ol/proj/Projection';
import Static from 'ol/source/ImageStatic';
import { Extent, getCenter, getHeight, getWidth } from 'ol/extent';
import { Vector as VectorSource } from 'ol/source';
import { Vector as VectorLayer } from 'ol/layer';
import Draw, { createBox, DrawEvent } from 'ol/interaction/Draw';
import GeoJSON from 'ol/format/GeoJSON';
import { DragBox, Modify, Select, Translate } from 'ol/interaction';
import { Fill, Icon, Stroke, Style, Text as TextFeature } from 'ol/style';
import CircleStyle from 'ol/style/Circle';
import Feature, { FeatureLike } from 'ol/Feature';
import { Geometry, LineString, MultiPoint, Point, Polygon } from 'ol/geom';
import { MapImage, Resource } from '../../types/game-document';
import { MapIllustrationContext } from './map-illustration-context';
import { MapAction } from './map-canvas';
import {
  click,
  never,
  platformModifierKeyOnly,
  primaryAction
} from 'ol/events/condition';
import Collection from 'ol/Collection';
import {
  MapEntity,
  TaskEntity,
  ZoneEntity
} from '../../types/game-document/entities';
import View from 'ol/View';
import { GameDocumentContext } from '../../contexts/game-document';
import {
  GetMapById,
  GetResourceValue,
  UpdateGameDocState,
  UpdateTaskAsync
} from '../../utils/game-document';
import { GetImageStyle, GetTaskPointStyle } from '../../utils/map-helper';
import debounce from 'lodash/debounce';
import cloneDeep from 'lodash.clonedeep';
import { TranslateEvent } from 'ol/interaction/Translate';
import { appStore } from '../../stores/app-store';
import { TaskRoute } from '../../types/game-document/entities/routes';
import { MapImagePropertyEditorWindow } from '../../features/game-document/maps/map-image-property-editor';
import {
  MAP_OBJECT_ALL,
  MAP_OBJECT_IMAGE,
  MAP_OBJECT_TASK,
  MAP_OBJECT_ZONE
} from '../../constants/map';
import { Coordinate } from 'ol/coordinate';
import { EventsKey } from 'ol/events';
import { toLonLat } from 'ol/proj';
import { unByKey } from 'ol/Observable';
import { Overlay } from 'ol';

// Map views always need a projection.  Here we just want to map image
// coordinates directly to map coordinates, so we create a projection that uses
// the image extent in pixels.
interface ImageResolution {
  height: number;
  width: number;
}
interface MapCanvasProps {
  url?: string;
  onAddZone?: (zone: ZoneEntity) => void;
  zones?: ZoneEntity[];
  onAddTask?: (coordinates: [number, number]) => void;
  tasks?: TaskEntity[];
  routes?: TaskRoute[];
  selectedPanel?: MapAction;
  isClearInteractions?: boolean;
  resourceImages?: Resource[];
  onSetMapTypeZone?: (e: any) => void;
  onEditZones?: (zones: ZoneEntity) => void;
  onEditTasks?: (tasks: TaskEntity) => void;
  onEditMapImages?: (mapImage: MapImage) => void;
  isEditZone?: boolean;
  assetMap?: MapEntity;
  mapImages?: MapImage[];
  onRemoveSelectAllAssets?: () => void;
  selectedObjectType?: 'ALL' | 'ZONE' | 'TASK' | 'TASK' | 'IMAGE' | 'AREA' | '';
  addTaskOverlay: (
    id: string,
    name: string,
    iconUrl: string,
    position: Coordinate | number[],
    isAddTaskMode?: boolean
  ) => void;
  updateTaskOverlayPosition: (
    id: string,
    newPosition: Coordinate | number[]
  ) => void;
  removeTaskOverlay: (id: string) => void;
}

const addTaskMode = {
  id: '00000',
  name: '',
  image: 'https://cdn.catalystglobal.games/resources/map-task.png'
};

const geojsonObject = {
  type: 'FeatureCollection',
  crs: {
    type: 'name',
    properties: {
      name: 'EPSG:3857'
    }
  },
  features: []
};

const tempSource = new VectorSource({
  features: new GeoJSON().readFeatures(geojsonObject)
});

function calculateCenter(geometry: any) {
  let center1: any, coordinates, minRadius;
  const type = geometry.getType();
  if (type === 'Polygon') {
    let x = 0;
    let y = 0;
    let i = 0;
    coordinates = geometry.getCoordinates()[0].slice(1);
    coordinates.forEach(function (coordinate: any) {
      x += coordinate[0];
      y += coordinate[1];
      i++;
    });
    center1 = [x / i, y / i];
  }
  let sqDistances;
  if (coordinates) {
    sqDistances = coordinates.map(function (coordinate: any) {
      const dx = coordinate[0] - center1[0];
      const dy = coordinate[1] - center1[1];
      return dx * dx + dy * dy;
    });
    minRadius = Math.sqrt(Math.max.apply(Math, sqDistances)) / 3;
  } else {
    minRadius =
      Math.max(
        getWidth(geometry.getExtent()),
        getHeight(geometry.getExtent())
      ) / 3;
  }
  return {
    center: center1,
    coordinates: coordinates,
    minRadius: minRadius,
    sqDistances: sqDistances
  };
}

const getImage = (url: string): Promise<ImageResolution> => {
  return new Promise((resolve, reject) => {
    let img = new Image();
    img.src = url;
    img.onload = () => {
      const imgRes: ImageResolution = {
        width: img.width,
        height: img.height
      };
      resolve(imgRes);
    };
    img.onerror = (e) => {
      reject(e);
    };
  });
};

const zoneStyle = new Style({
  geometry: function (feature) {
    const modifyGeometry = feature.get('modifyGeometry');
    return modifyGeometry ? modifyGeometry.geometry : feature.getGeometry();
  },
  fill: new Fill({
    color: 'rgba(255, 255, 255, 0.2)'
  }),
  stroke: new Stroke({
    color: [255, 85, 39],
    width: 2
  }),
  image: new CircleStyle({
    radius: 7,
    fill: new Fill({
      color: [255, 85, 39]
    })
  })
});

const MapIllustrationCanvas = ({
  url,
  onAddZone,
  zones,
  selectedPanel,
  onAddTask,
  tasks,
  routes,
  isClearInteractions,
  resourceImages,
  onEditZones,
  onEditTasks,
  isEditZone,
  assetMap,
  mapImages,
  addTaskOverlay,
  removeTaskOverlay,
  updateTaskOverlayPosition,
  onEditMapImages = () => {},
  onRemoveSelectAllAssets = () => {},
  selectedObjectType
}: MapCanvasProps) => {
  const [state, setState] = useContext(GameDocumentContext);
  let map = useContext(MapIllustrationContext);
  const mapRef = useRef<HTMLDivElement>(null);
  const [imageIllustration, setImageIllustration] = useState<ImageResolution>();
  const [imageLayer, setImageLayer] = useState<any>();
  const maps = isEditZone ? assetMap : state.gameDocument?.assets.maps![0];
  const [selectedImageId, setSelectedImageId] = useState<string>('');
  const [showImageEditor, setShowImageEditor] = useState<boolean>(false);
  const selectRef = useRef<Select | null>(null);
  const [moveListenerKeys, setMoveListenerKeys] = useState<EventsKey[]>([]);
  const [clickListenerKeys, setClickListenerKeys] = useState<EventsKey[]>([]);

  const OBJECT_TYPE = 'objectType';

  const onMapMoveEnd = debounce(() => {
    setState((prev) => {
      const newGameDoc = cloneDeep(prev);
      const mapEntity = GetMapById(
        newGameDoc.gameDocument!,
        isEditZone
          ? assetMap?.id!
          : newGameDoc?.gameDocument?.rules?.worldMap?.mapAssId!
      );
      const center = map.getView().getCenter();
      if (mapEntity && center) {
        mapEntity.zoomLevel = map.getView().getZoom();
        mapEntity.longitude = center[0];
        mapEntity.latitude = center[1];
      }
      return UpdateGameDocState(prev, newGameDoc.gameDocument!);
    });
  }, 200);

  useEffect(() => {
    RemoveImageFeature();
    setMapImages();
    if (selectedObjectType) {
      selectAllObject();
    }
  }, [
    map,
    selectedPanel,
    resourceImages,
    JSON.stringify(mapImages),
    selectedObjectType
  ]);

  const RemoveLineFeature = () => {
    const layers = map.getLayers().getArray();

    for (const layer of layers) {
      if (layer instanceof VectorLayer) {
        const source = layer.getSource();
        if (source) {
          const features = source.getFeatures();

          for (const feature of features) {
            let featureName = feature.get('name') as string;

            if (featureName && featureName.includes('Line between task-')) {
              map.removeLayer(layer);
            }
          }
        }
      }
    }
  };

  const RemoveLineFeatureByName = (name: string) => {
    const layers = map.getLayers().getArray();

    for (const layer of layers) {
      if (layer instanceof VectorLayer) {
        const source = layer.getSource();
        if (source) {
          const features = source.getFeatures();

          for (const feature of features) {
            let featureName = feature.get('name') as string;

            if (featureName && featureName === name) {
              map.removeLayer(layer);
            }
          }
        }
      }
    }
  };

  const RemoveImageFeature = () => {
    const layers = map.getLayers().getArray();

    for (const layer of layers) {
      if (layer instanceof VectorLayer) {
        const source = layer.getSource();
        if (source) {
          const features = source.getFeatures();

          for (const feature of features) {
            let featureName = feature.get('name') as string;

            if (featureName && featureName.includes('map-image-')) {
              map.removeLayer(layer);
            }
          }
        }
      }
    }
  };

  const setMapImages = () => {
    if (map) {
      RemoveImageFeature();
      mapImages
        ?.filter((x) => x.isVisible)
        .forEach((image) => {
          let lineName = `map-image-${image.id}`;
          let points = image?.boundary?.geometry?.coordinates as any;

          let lineBetweenTwoFeatures = new Feature<Geometry>({
            geometry: new Point(points as any),
            name: lineName,
            id: image.id
          });

          lineBetweenTwoFeatures.setId(image?.id);

          lineBetweenTwoFeatures.set(OBJECT_TYPE, MAP_OBJECT_IMAGE);

          lineBetweenTwoFeatures.setStyle(
            GetImageStyle(
              GetResourceValue(state?.gameDocument!, image?.imageResId!) ?? '',
              GetResourceValue(state?.gameDocument!, image?.titleResId!) ?? '',
              image?.opacity! / 100,
              map.getView().getResolution(),
              (image?.imageScale ?? 25) / 100
            )
          );

          let source = new VectorSource({
            features: [lineBetweenTwoFeatures],
            wrapX: false
          });

          const taskLayer = new VectorLayer({
            source: source
          });

          taskLayer.set('name', MAP_OBJECT_IMAGE);

          if (mapImages && mapImages.length > 0) {
            map.addLayer(taskLayer);
          } else {
            map.removeLayer(taskLayer);
          }

          //#region create Modify() to handle task drag and drop
          const mapImageModify = new Modify({
            hitDetection: taskLayer,
            source: source
          });
          const modifyStartEventHandler = (event: any) => {
            let features = event.features as Collection<Feature>;
            features.forEach(function (feature) {
              feature.set(
                'modifyGeometry',
                { geometry: feature.getGeometry()?.clone() },
                true
              );
            });
          };

          const taskModifyEndEventHandler = (event: any) => {
            let features = event.features as Collection<Feature>;
            features.forEach(function (feature: Feature) {
              const modifyGeometry = feature.get('modifyGeometry');
              if (modifyGeometry) {
                let lastPoint = feature.getGeometry();
                let featureId = feature.getId()?.toString() ?? '';
                updateFeatureImage(
                  featureId,
                  modifyGeometry.geometry as Geometry,
                  lastPoint as Geometry
                );
              }
            });
          };

          const selectInteraction = new Select({
            layers: [taskLayer],
            condition: click
          });

          selectInteraction.on('select', (event) => {
            if (event.selected.length > 0) {
              const selectedFeature = event.selected[0];
              // Handle the click event for the selected feature
              // You can access the feature properties using selectedFeature.getProperties()
              // For example, selectedFeature.getId() to get the feature ID

              let id = (selectedFeature.getProperties()?.name as string)?.split(
                'map-image-'
              );

              setSelectedImageId(id[1]);
              setShowImageEditor(!showImageEditor);
            }
          });

          mapImageModify.on('modifystart', modifyStartEventHandler);
          mapImageModify.on('modifyend', taskModifyEndEventHandler);
          map.addInteraction(mapImageModify);
          map.addInteraction(selectInteraction);
        });
      //#endregion
    }
  };

  const updateFeatureImage = (
    id: string,
    geometry: Geometry,
    lastPoint: Geometry
  ) => {
    if (mapImages) {
      let newImages: MapImage[] = cloneDeep(mapImages ?? []);
      let data = lastPoint as Point;
      let coordinate = data.getCoordinates();
      let item = newImages.find((x) => x.id === id);
      let index = -1;
      let selectedIndex = newImages.findIndex((x) => x?.id === id);
      if (item && item.boundary) {
        item.boundary.geometry.coordinates = [
          coordinate[0],
          coordinate[1]
        ] as any;
        newImages[index] = item;
        onEditMapImages(newImages[selectedIndex]);
      }
    }
  };

  const setLineString = (
    id: string,
    startCoordinate: number[],
    endCoordinate: number[]
  ) => {
    let lineName = `Line between task-${id}`;

    RemoveLineFeatureByName(lineName);

    let points = [startCoordinate, endCoordinate];

    let lineBetweenTwoFeatures = new Feature({
      geometry: new LineString(points),
      name: lineName
    });

    // Calculate the mid-point of the line
    let midPoint = [
      (startCoordinate[0] + endCoordinate[0]) / 2,
      (startCoordinate[1] + endCoordinate[1]) / 2
    ];

    // Calculate the rotation angle to make the arrow point towards the end coordinate
    let rotation = Math.atan2(
      endCoordinate[1] - startCoordinate[1],
      endCoordinate[0] - startCoordinate[0]
    );
    rotation = (rotation - Math.PI / 2) * -1;

    // Create a new arrow feature
    let arrowFeature = new Feature(new Point(midPoint));

    // Create a style for the arrow
    let arrowStyle = new Style({
      image: new Icon({
        src: '/navigation.png',
        scale: 0.07, // Adjust the scale as needed
        rotateWithView: true,
        rotation: rotation
      })
    });

    // Set the style to the arrow feature
    arrowFeature.setStyle(arrowStyle);

    // Create a text feature for the title
    const titleFeature = new Feature({
      geometry: new Point(midPoint)
    });

    // Create a style for the arrow
    let textStyle = new Style({
      text: new TextFeature({
        text: routes?.find((x) => x.id === id)?.titleResId! ?? '',
        offsetY: -20, // Adjust the offset to position the title
        font: 'bold 18px Calibri,sans-serif',
        fill: new Fill({
          color: 'black'
        })
      })
    });

    titleFeature.setStyle(textStyle as any);

    let source = new VectorSource({
      features: [lineBetweenTwoFeatures, arrowFeature as any, titleFeature],
      wrapX: false
    });

    const taskLayer = new VectorLayer({
      source: source,
      style: [
        new Style({
          stroke: new Stroke({
            color: '#408CBB',
            width: 5,
            lineCap: 'butt'
          })
        }),
        arrowStyle
      ]
    });

    if (routes && routes.length > 0) {
      map.addLayer(taskLayer);
    } else {
      map.removeLayer(taskLayer);
    }
  };

  const dragBox = new DragBox({
    condition: platformModifierKeyOnly
  });

  const onAddDragBox = () => {
    let tempBoxLayer: any; // Variable to store the temporary box layer

    const layers = map.getAllLayers();
    // dragBox.setStyle(dragBoxStyle);

    map.addInteraction(dragBox);

    const select = new Select({
      condition: click,
      layers: [layers as any] // Use an array with VectorLayer
    });

    map.addInteraction(select);

    selectRef.current = select;

    let allFeatures: Feature[] = [];

    dragBox.on('boxstart', function () {
      // Style for the box at the start of the drag
      const startStyle = new Style({
        stroke: new Stroke({
          color: 'blue',
          width: 2
        }),
        fill: new Fill({
          color: 'rgba(0, 0, 255, 0.1)'
        })
      });

      tempBoxLayer = new VectorLayer({
        source: new VectorSource({
          features: [new Feature(dragBox.getGeometry())]
        }),
        style: startStyle
      });

      map.addLayer(tempBoxLayer);
    });

    dragBox.on('boxdrag', function () {
      // Style for the box during the drag
      const dragStyle = new Style({
        stroke: new Stroke({
          color: '#69a2ff',
          width: 2
        }),
        fill: new Fill({
          color: 'rgba(255, 255, 255, 0.5)'
        })
      });

      tempBoxLayer.setStyle(dragStyle);
    });

    dragBox.on('boxend', function () {
      map.removeLayer(tempBoxLayer);
      map.getInteractions().forEach((interaction) => {
        if (interaction instanceof Select) {
          map.removeInteraction(interaction);
        }

        if (interaction instanceof Translate) {
          map.removeInteraction(interaction);
        }
      });

      tempBoxLayer = undefined;

      const boxExtent = dragBox.getGeometry().getExtent();

      map.getLayers().forEach((layer) => {
        if (layer instanceof VectorLayer) {
          const source = layer.getSource();
          if (source instanceof VectorSource) {
            // Collect features from the source
            const features = source.getFeatures();

            if (selectedObjectType === MAP_OBJECT_ALL) {
              allFeatures.push(...features);
            } else {
              if (layer.get('name') === selectedObjectType) {
                // Filter features based on the selected object type
                const filteredFeatures = features.filter((feature) => {
                  const objectType = feature.get(OBJECT_TYPE);
                  return objectType === selectedObjectType;
                });

                allFeatures.push(...filteredFeatures);
              }
            }
          }
        }
      });

      const filteredFeatures = allFeatures.filter((feature) => {
        const featureGeometry = feature.getGeometry();
        if (featureGeometry) {
          return intersectsExtent(featureGeometry.getExtent(), boxExtent);
        }
        return false;
      });

      select.getFeatures().clear();
      select.getFeatures().extend(filteredFeatures);

      // Create a Translate interaction
      const translate = new Translate({
        features: select.getFeatures()
      });

      // Add the Translate interaction to the map
      map.addInteraction(translate);

      // Handle the "translateend" event
      translate.on('translateend', (event) => {
        // Explicitly convert Collection to Feature array
        const translatedFeatures =
          event.features.getArray() as Feature<Geometry>[];

        translatedFeatures?.forEach((element) => {
          let objectType = element.get(OBJECT_TYPE);
          let lastPoint = element.getGeometry();
          let featureId = element.getId()?.toString() ?? '';

          if (objectType === MAP_OBJECT_ZONE) {
            updateFeatureZone(
              featureId,
              element.getGeometry() as Geometry,
              lastPoint as Geometry
            );
          } else if (objectType === MAP_OBJECT_TASK) {
            updateFeatureTask(
              featureId,
              element.getGeometry() as Geometry,
              lastPoint as Geometry
            );
          } else if (objectType === MAP_OBJECT_IMAGE) {
            updateFeatureImage(
              featureId,
              element.getGeometry() as Geometry,
              lastPoint as Geometry
            );
          }
        });

        // Remove the select and translate interactions when done
        map.removeInteraction(select);
        map.removeInteraction(translate);
      });
    });
  };

  function intersectsExtent(featureExtent: any, boxExtent: any) {
    return (
      featureExtent[0] < boxExtent[2] &&
      featureExtent[2] > boxExtent[0] &&
      featureExtent[1] < boxExtent[3] &&
      featureExtent[3] > boxExtent[1]
    );
  }

  const selectAllObject = () => {
    const layers = map.getAllLayers();

    const select = new Select({
      condition: click,
      layers: [layers as any] // Use an array with VectorLayer
    });

    map.addInteraction(select);

    selectRef.current = select;

    const allFeatures: Feature[] = [];

    map.getLayers().forEach((layer) => {
      if (layer instanceof VectorLayer) {
        const source = layer.getSource();
        if (source instanceof VectorSource) {
          // Collect features from the source
          const features = source.getFeatures();

          if (selectedObjectType === MAP_OBJECT_ALL) {
            allFeatures.push(...features);
          } else {
            features?.forEach((feature) => {
              let objectType = feature.get(OBJECT_TYPE);
              if (objectType === selectedObjectType) {
                allFeatures.push(feature);
              }
            });
          }
        }
      }
    });

    select.getFeatures().clear();
    select.getFeatures().extend(allFeatures);

    // Create a Translate interaction
    const translate = new Translate({
      features: select.getFeatures()
    });

    // Add the Translate interaction to the map
    map.addInteraction(translate);

    const startPixels = { x: 0, y: 0 };
    const overlays: Overlay[] = [];
    const updatedOverlayPositions: [number, number][] = [];
    if (selectedObjectType === 'ALL') {
      map
        .getOverlays()
        .getArray()
        .forEach((ov) => overlays.push(ov));
    }

    translate.on('translatestart', (event) => {
      const translatedFeature =
        event.features.getArray()[0] as Feature<Geometry>;
      const geometry = translatedFeature.getGeometry();
      const startFeaturePosition = map.getPixelFromCoordinate(
        (geometry as Polygon).getFirstCoordinate()
      );
      startPixels.x = startFeaturePosition[0];
      startPixels.y = startFeaturePosition[1];
    });

    translate.on('translating', (event) => {
      const translatedFeature =
        event.features.getArray()[0] as Feature<Geometry>;
      const geometry = translatedFeature.getGeometry();
      const newFeaturePosition = map.getPixelFromCoordinate(
        (geometry as Polygon).getFirstCoordinate()
      );

      const deltaX = newFeaturePosition[0] - startPixels.x;
      const deltaY = newFeaturePosition[1] - startPixels.y;

      overlays.forEach((ov, idx) => {
        const currentCoordinate = ov.getPosition();

        if (currentCoordinate) {
          const currentPixel = map.getPixelFromCoordinate(currentCoordinate);
          const newPixelPosition = [
            currentPixel[0] + deltaX,
            currentPixel[1] + deltaY
          ];
          const newCoordinatePosition =
            map.getCoordinateFromPixel(newPixelPosition);
          ov.setPosition(newCoordinatePosition);
          updatedOverlayPositions[idx] = [
            newCoordinatePosition[0],
            newCoordinatePosition[1]
          ];
        }
      });

      startPixels.x = newFeaturePosition[0];
      startPixels.y = newFeaturePosition[1];
    });

    // Handle the "translateend" event
    translate.on('translateend', (event) => {
      // Explicitly convert Collection to Feature array
      const translatedFeatures =
        event.features.getArray() as Feature<Geometry>[];

      translatedFeatures?.forEach((element) => {
        let objectType = element.get(OBJECT_TYPE);
        let lastPoint = element.getGeometry();
        let featureId = element.getId()?.toString() ?? '';

        if (objectType === MAP_OBJECT_ZONE) {
          updateFeatureZone(
            featureId,
            element.getGeometry() as Geometry,
            lastPoint as Geometry
          );
        } else if (objectType === MAP_OBJECT_TASK) {
          updateFeatureTask(
            featureId,
            element.getGeometry() as Geometry,
            lastPoint as Geometry
          );
        } else if (objectType === MAP_OBJECT_IMAGE) {
          updateFeatureImage(
            featureId,
            element.getGeometry() as Geometry,
            lastPoint as Geometry
          );
        }
      });

      if (tasks && updatedOverlayPositions.length > 0) {
        overlays.forEach((ov, idx) => {
          const taskId = ov.getId();
          if (taskId) {
            const selectedTask = tasks.find((task) => task.id === taskId);
            if (state.gameDocument && selectedTask && selectedTask.boundary) {
              selectedTask.boundary.geometry.coordinates =
                updatedOverlayPositions[idx];

              UpdateTaskAsync(
                state.gameDocument,
                taskId as string,
                selectedTask
              ).then((response) => {
                setState((prev) => UpdateGameDocState(prev, response));
              });
            }
          }
        });
      }

      // Remove the select and translate interactions when done
      map.removeInteraction(select);
      map.removeInteraction(translate);
    });
  };

  useEffect(() => {
    if (selectedObjectType === '') {
      onRemoveSelectAllAssets();
    } else {
      selectAllObject();
      onAddDragBox();
    }
  }, [map, selectedObjectType]);

  useEffect(() => {
    if (url) {
      appStore.showLoading();
      getImage(url)
        .then((res) => setImageIllustration(res))
        .finally(() => {
          appStore.hideLoading();
        });
    }
  }, [url]);

  useEffect(() => {
    if (imageIllustration) {
      //#region create map Image Layer
      map.setTarget(mapRef.current as HTMLElement);

      let extent: Extent;
      extent = [0, 0, imageIllustration.width, imageIllustration.height];
      const projection = new Projection({
        code: 'xkcd-image',
        units: 'pixels',
        extent: extent
      });
      map.setView(
        new View({
          projection: projection,
          center:
            maps!.latitude !== undefined && maps!.longitude !== undefined
              ? [maps!.longitude, maps!.latitude]
              : getCenter(extent),
          extent,
          zoom: 1
        })
      );
      if (maps!.zoomLevel !== undefined) {
        map.getView().setZoom(maps?.zoomLevel!);
      }
      const imageLayer = new ImageLayer({
        source: new Static({
          attributions: '',
          url: url ?? '',
          projection: projection,
          imageExtent: extent
        })
      });

      setImageLayer(imageLayer);
      const layers = map.getAllLayers();
      if (layers.length > 0) {
        layers[0] = imageLayer;
        map.setLayers(layers);
      } else {
        map.setLayers([imageLayer]);
      }

      //#endregion
    }
  }, [imageIllustration, map, isEditZone, url]);

  useEffect(() => {
    if (imageLayer && !isEditZone) {
      const zoneFeatures: Feature<Geometry>[] = [];

      //#region populate zone features from zones Props
      if (zones) {
        let worldZones = zones.filter((x) => x.isVisible);
        worldZones.forEach((worldZone) => {
          const geoJsonFeatures = new GeoJSON().readFeature(
            worldZone.boundary as never
          );

          if (geoJsonFeatures instanceof Feature) {
            geoJsonFeatures.setStyle((feature) => {
              const styles = [zoneStyle];
              const modifyGeometry = feature.get('modifyGeometry');
              const geometry = modifyGeometry
                ? modifyGeometry.geometry
                : feature.getGeometry();
              const result = calculateCenter(geometry);
              const center = result.center;
              if (center) {
                const coordinates = result.coordinates;
                if (coordinates) {
                  const minRadius = result.minRadius;
                  const sqDistances = result.sqDistances;
                  const rsq = minRadius * minRadius;
                  const points = coordinates.filter(function (
                    coordinate: any,
                    index: any
                  ) {
                    return sqDistances[index] > rsq;
                  });
                  styles.push(
                    new Style({
                      geometry: new MultiPoint(points),
                      image: new CircleStyle({
                        radius: 4,
                        fill: new Fill({
                          color: [255, 85, 39]
                        })
                      })
                    })
                  );
                }
              }

              return styles;
            });

            geoJsonFeatures.set('objectType', MAP_OBJECT_ZONE);

            zoneFeatures.push(geoJsonFeatures);
          }
        });
      }
      //#endregion

      //#region create zone vector source & zone layer
      const zoneSource = new VectorSource({
        features: zoneFeatures
      });

      const zoneLayer = new VectorLayer({
        source: zoneSource
      });

      zoneLayer.set('name', MAP_OBJECT_ZONE);

      map.addLayer(zoneLayer);
      //#endregion

      //#region create Modify() to handle zone resize
      const zoneModify = new Modify({
        hitDetection: zoneLayer,
        source: zoneSource,
        condition: function (event) {
          return primaryAction(event) && !platformModifierKeyOnly(event);
        },
        deleteCondition: never,
        insertVertexCondition: never,
        style: function (feature: FeatureLike) {
          feature.get('features').forEach(function (modifyFeature: any) {
            const modifyGeometry = modifyFeature.get('modifyGeometry');
            if (modifyGeometry) {
              let ft = (feature as Feature).getGeometry() as Point;
              const point = ft.getCoordinates();
              let modifyPoint = modifyGeometry.point;
              if (!modifyPoint) {
                // save the initial geometry and vertex position
                modifyPoint = point;
                modifyGeometry.point = modifyPoint;
                modifyGeometry.geometry0 = modifyGeometry.geometry;
                // get anchor and minimum radius of vertices to be used
                const result = calculateCenter(modifyGeometry.geometry0);
                modifyGeometry.center = result.center;
                modifyGeometry.minRadius = result.minRadius;
              }

              const center = modifyGeometry.center;
              const minRadius = modifyGeometry.minRadius;
              let dx, dy;
              if (modifyPoint.length > 0 && center) {
                dx = modifyPoint[0] - center[0];
                dy = modifyPoint[1] - center[1];
                const initialRadius = Math.sqrt(dx * dx + dy * dy);
                if (initialRadius > minRadius) {
                  dx = point[0] - center[0];
                  dy = point[1] - center[1];
                  const currentRadius = Math.sqrt(dx * dx + dy * dy);
                  if (currentRadius > 0) {
                    const geometry = modifyGeometry.geometry0.clone();
                    geometry.scale(
                      currentRadius / initialRadius,
                      undefined,
                      center
                    );
                    modifyGeometry.geometry = geometry;
                  }
                }
              }
            }
          });
        }
      });

      const modifyStartEventHandler = (event: any) => {
        let features = event.features as Collection<Feature>;

        features.forEach(function (feature) {
          feature.set(
            'modifyGeometry',
            { geometry: feature.getGeometry()?.clone() },
            true
          );
        });
      };

      const zoneModifyEndEventHandler = (event: any) => {
        let features = event.features as Collection<Feature>;
        features.forEach(function (feature: Feature) {
          const modifyGeometry = feature.get('modifyGeometry');
          if (modifyGeometry) {
            let lastPoint = feature.getGeometry();
            let featureId = feature.getId()?.toString() ?? '';

            updateFeatureZone(
              featureId,
              modifyGeometry.geometry as Geometry,
              lastPoint as Geometry
            );
          }
        });
      };

      zoneModify.on('modifystart', modifyStartEventHandler);
      zoneModify.on('modifyend', zoneModifyEndEventHandler);

      map.addInteraction(zoneModify);

      //#endregion

      //#region create Select() to handle zone drag and drop
      const select = new Select({ layers: [zoneLayer] });
      let fts = select.getFeatures();

      const translate = new Translate({
        features: fts
      });

      const translateStartHandler = (evt: TranslateEvent) => {
        evt.features.forEach((feat) => {});
      };

      const translateEndHandler = (evt: TranslateEvent) => {
        evt.features.forEach((feat) => {
          let lastPoint = feat.getGeometry();
          let featureId = feat.getId()?.toString() ?? '';
          updateFeatureZone(
            featureId,
            feat.getGeometry() as Geometry,
            lastPoint as Geometry
          );
        });
      };

      translate.on('translatestart', translateStartHandler);
      translate.on('translateend', translateEndHandler);

      if (selectedPanel === '') {
        map.addInteraction(select);
      }

      map.addInteraction(translate);

      //#endregion

      //#region add Draw Event to handle add new zone
      let drawZone: any;

      const drawZoneHandler = (event: DrawEvent) => {
        if (onAddZone) {
          if (event && event.feature) {
            let data = event.feature.getGeometry() as Polygon;
            let coordinates = data.getCoordinates();

            onAddZone({
              id: '',
              name: '',
              description: '',
              boundary: {
                id: '',
                type: 'Polygon',
                geometry: coordinates as any
              }
            });
          }
        }
      };

      if (selectedPanel === 'Zone') {
        let geometryFunction;

        geometryFunction = createBox();

        drawZone = new Draw({
          source: tempSource,
          type: 'Circle',
          geometryFunction: geometryFunction
        });

        drawZone.on('drawend', drawZoneHandler);

        map.addInteraction(drawZone);
      }
      //#endregion

      if (selectedObjectType) {
        selectAllObject();
      }

      //#region remove event listener, interaction, layer, when unmount
      return () => {
        zoneModify.un('modifystart', modifyStartEventHandler);
        zoneModify.un('modifyend', zoneModifyEndEventHandler);
        translate.un('translateend', translateEndHandler);
        translate.un('translatestart', translateStartHandler);
        if (selectedPanel === 'Zone') {
          drawZone.un('drawend', drawZoneHandler);
          map.removeInteraction(drawZone);
        }
        map.removeInteraction(zoneModify);
        map.removeInteraction(select);

        map.removeLayer(zoneLayer);
      };
      //#endregion
    }
  }, [
    imageLayer,
    isEditZone,
    map,
    selectedPanel,
    JSON.stringify(zones),
    selectedObjectType,
    mapImages
  ]);

  const updateFeatureZone = (
    id: string,
    geometry: Geometry,
    lastPoint: Geometry
  ) => {
    if (zones) {
      let dataPolygon = geometry as Polygon;
      let coordinatePolygon = dataPolygon.getCoordinates();

      let itemPolygon = zones.find((x) => x.boundary?.id === id);
      if (itemPolygon && itemPolygon.boundary) {
        itemPolygon.boundary.geometry.coordinates = coordinatePolygon as any;
      }

      let selectedIndex = zones.findIndex((x) => x.boundary?.id === id);

      if (onEditZones && zones) {
        onEditZones(zones[selectedIndex]);
      }
    }
  };

  const updateFeatureTask = (
    id: string,
    geometry: Geometry,
    lastPoint: Geometry
  ) => {
    if (tasks) {
      let newTasks: TaskEntity[] = cloneDeep(tasks);
      let data = lastPoint as Point;
      let coordinate = data.getCoordinates();

      let item = newTasks.find((x) => x.boundary?.id === id);

      let index = -1;

      let selectedIndex = newTasks.findIndex((x) => x.boundary?.id === id);

      if (item && item.boundary) {
        item.boundary.geometry.coordinates = [
          coordinate[0],
          coordinate[1]
        ] as any;

        newTasks[index] = item;
        if (onEditTasks) {
          onEditTasks(newTasks[selectedIndex]);
        }
      }
    }
  };

  useEffect(() => {
    map.on('moveend', onMapMoveEnd);

    map.on('click', function (event) {
      onRemoveSelectAllAssets();
    });

    return () => {
      map.un('moveend', onMapMoveEnd);
    };
  }, [map, onMapMoveEnd]);

  useEffect(() => {
    RemoveLineFeature();
    if (routes && routes.length > 0) {
      routes.forEach((item) => {
        // Get Location start task and end task - Latitude and Longitude
        const startCoordinate =
          tasks?.find((i) => i.id === item.fromTaskId)?.boundary?.geometry
            ?.coordinates ?? [];
        const endCoordinate =
          tasks?.find((i) => i.id === item.toTaskId)?.boundary?.geometry
            ?.coordinates ?? [];
        if (startCoordinate.length > 0 && endCoordinate.length > 0) {
          setLineString(item.id, startCoordinate, endCoordinate);
        }

        map.getView();
      });
    }
  }, [routes, tasks]);

  const [moveListener, setMoveListener] = useState<EventsKey | null>(null);
  const [clickListener, setClickListener] = useState<EventsKey | null>(null);

  useEffect(() => {
    if (selectedPanel === 'Task' && map) {
      const { id, image, name } = addTaskMode;
      let defaultImage = image;

      if (
        state.gameDocument?.settings.designer &&
        state.gameDocument.settings.designer.defaultTaskAvailableIconResId
      ) {
        defaultImage = GetResourceValue(
          state.gameDocument,
          state.gameDocument.settings.designer.defaultTaskAvailableIconResId
        );
      }

      addTaskOverlay(id, name, defaultImage, [0, 0], true);

      const moveListenerKey = map.on('pointermove', (event) => {
        const coordinate = event.coordinate;
        updateTaskOverlayPosition(id, toLonLat(coordinate));
      });

      const clickListenerKey = map.on('click', (event) => {
        const coordinate = event.coordinate;
        onAddTask && onAddTask([coordinate[0], coordinate[1]]);
      });

      setMoveListener(moveListenerKey);
      setClickListener(clickListenerKey);

      moveListenerKeys.push(moveListenerKey);
      clickListenerKeys.push(clickListenerKey);
    }
  }, [selectedPanel, map, state.gameDocument]);

  useEffect(() => {
    if (selectedPanel !== 'Task' && map) {
      if (moveListener) unByKey(moveListener);
      if (clickListener) unByKey(clickListener);
      removeTaskOverlay(addTaskMode.id);

      if (moveListenerKeys.length > 0) {
        moveListenerKeys.forEach((moveListenerKey) => {
          unByKey(moveListenerKey);
        });
      }

      if (clickListenerKeys.length > 0) {
        clickListenerKeys.forEach((clickListenerKey) => {
          unByKey(clickListenerKey);
        });
      }
    }
  }, [selectedPanel, map, moveListener, clickListener]);

  return (
    <div className={'position-relative h-full w-full'}>
      <div id={'map'} ref={mapRef as any} className={'map w-full h-full'} />
      {showImageEditor && (
        <MapImagePropertyEditorWindow
          id={selectedImageId}
          onClose={() => setShowImageEditor(false)}
          onChange={() => {}}
        />
      )}
    </div>
  );
};

export default MapIllustrationCanvas;
