import React from 'react';
import { Drawer, DrawerContent } from '@progress/kendo-react-layout';

import { LayoutContext } from '../../contexts/layout-provider';

const Item: React.FC = () => {
  const layout = React.useContext(LayoutContext);
  return layout.drawerMobileMenu;
};

const DrawerMobileContainer: React.FC<{ children: React.ReactNode }> = ({
  children
}) => {
  const layout = React.useContext(LayoutContext);
  return (
    <Drawer
      item={Item}
      animation={{
        duration: 400
      }}
      mode={'overlay'}
      position={'start'}
      className={'bg-white'}
      onOverlayClick={layout.toggleMobileNavIsCollapsed}
      items={[{ text: 'Inbox', selected: true }]}
      expanded={!layout.mobileNavIsCollapsed}>
      <DrawerContent>{children}</DrawerContent>
    </Drawer>
  );
};

export default DrawerMobileContainer;
